<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- account section -->
            <section class="checkout-section section-gap">
                <div class="section-title-wrap">
                    <div class="section-title">{{ $t("Checkout") }}</div>
                </div>
                <div class="alert alert-success d-flex align-items-center" v-if="successLoading">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="mr-2"
                         style="shape-rendering: auto;" width="40px" height="40px" viewBox="0 0 100 100"
                         preserveAspectRatio="xMidYMid">
                        <circle cx="50" cy="50" r="24" stroke-width="4" stroke="#155724"
                                stroke-dasharray="37.69911184307752 37.69911184307752" fill="none"
                                stroke-linecap="round" transform="rotate(300.791 50 50)">
                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                                              dur="1.01010101010101s" keyTimes="0;1"
                                              values="0 50 50;360 50 50"></animateTransform>
                        </circle>
                    </svg>
                    <p class="text-capitalize">{{ $t('please wait') }}. . .</p>
                </div>
                <div class="row" v-else>
                    <div class="col-md-7">
                        <form action="">
                            <div class="checkout-card details__card gutter-10">
                                <div class="details__card__head">
                                    <h4 class="font-weight-bold">{{ $t("Billing Details") }}</h4>
                                </div>
                                <div class="details__card__body form-box">
                                    <div class="form-group">
                                        <label for="address_line_1">{{ $t("Street") }}*</label>
                                        <input type="text" v-model="form.street" id="address_line_1"
                                               class="form-control" :placeholder="$t('Enter Street')">
                                        <div class="error-message" v-if="errors.street">{{
                                                $t(errors.street.join(' '))
                                            }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="country">{{ $t("Country") }}*</label>

                                                <multiselect
                                                        id="country"
                                                        v-model="form.country"
                                                        :options="countryOptions"
                                                        :placeholder="$t('Select Country')"
                                                        @open="findCountry"
                                                        track-by="id"
                                                        label="name"
                                                        :class="{'disabled' : form.is_remote}"
                                                ></multiselect>
                                                <div class="error-message" v-if="errors.country">
                                                    {{ $t(errors.country.join(' ')) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="state">{{ $t("State") }}*</label>
                                                <multiselect
                                                        id="state"
                                                        v-model="form.state"
                                                        :options="stateOptions"
                                                        :placeholder="$t('Select State')"
                                                        @open="findState"
                                                        track-by="id"
                                                        label="name"
                                                        :class="{'disabled' : form.is_remote}"
                                                ></multiselect>
                                                <div class="error-message" v-if="errors.state">
                                                    {{ $t(errors.state.join(' ')) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="city">{{ $t("City") }}*</label>

                                                <multiselect
                                                        id="city"
                                                        v-model="form.city"
                                                        :options="cityOptions"
                                                        :placeholder="$t('Select City')"
                                                        @open="findCity"
                                                        track-by="id"
                                                        label="name"
                                                        :class="{'disabled' : form.is_remote}"
                                                ></multiselect>
                                                <div class="error-message" v-if="errors.city">
                                                    {{ $t(errors.city.join(' ')) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="postal_code">{{ $t("Postal Code") }}*</label>
                                                <input type="text" v-model="form.postal_code" id="postal_code"
                                                       class="form-control" :placeholder="$t('Enter Postal code')">
                                                <div class="error-message" v-if="errors.postal_code">
                                                    {{ $t(errors.postal_code.join(' ')) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" v-if="hasCouponOption">
                                        <label for="coupon">{{ $t('I have a Coupon Code') }}</label>
                                        <div class="d-flex">
                                            <input type="text" id="coupon" class="form-control" v-model="couponCode"
                                                   :placeholder="$t('Enter Coupon Code')"/>
                                            <button class="button semi-button-info ml-2" :disabled="isLoading"
                                                    @click.prevent="couponDiscount">{{ $t('apply') }}
                                            </button>
                                        </div>
                                        <error-message :message="errors.coupon"/>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mt-4 mb-4">
                                <label class="checkbox mt-3 checkout-checkbox">
                                    <input type="checkbox" v-model="agree" value="true"/>
                                    <span>{{ $t("i agree with to the terms and policy") }}*</span>
                                </label>

                                <submit-button :click="checkout" :loading="isLoading" :disabled="button.disabled">
                                    {{ $t("Checkout") }}
                                </submit-button>

                            </div>
                            <div class="sponsor-image mb-5">
                                <img :src="sponsorImage" alt="sponsor"/>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-5">
                        <div class="summary-card details__card gutter-10">
                            <div class="details__card__head">
                                <h4 class="font-weight-bold">{{ $t("card summary") }}</h4>
                            </div>
                            <div class="details__card__body">
                                <div class="label__full label__full--primary">
                                    <p class="color-dark">{{ $t("Plan Name") }}: <span
                                            class="color-secondary">{{ package.name }}</span></p>
                                    <p class="color-dark">{{ $t("Description") }}: <span
                                            class="color-secondary">{{ package.desc }}</span></p>
                                    <p class="color-dark d-flex justify-content-between">{{ $t("Plan Price") }}: <span
                                            class="color-secondary">${{ package.price }}/{{ package.plan }}</span></p>
                                    <p class="color-dark d-flex justify-content-between"
                                       v-if="package.has_discount && !package.coupon_discount">{{ $t("discount") }}
                                        <template v-if="package.discount_text">({{ package.discount_text }})</template>
                                        :
                                        <span class="color-secondary">-${{ package.discount }}/{{ package.plan }}</span>
                                    </p>
                                    <p class="color-dark d-flex justify-content-between" v-if="package.coupon_discount">
                                        {{ $t("Coupon Discount") }}
                                        <template v-if="package.discount_text">({{ package.discount_text }})</template>
                                        : <span
                                            class="color-secondary">-${{ package.coupon_discount }}/{{ package.plan }}</span>
                                    </p>
                                    <p class="color-dark d-flex justify-content-between total-amount">{{ $t("Total") }}:
                                        <span class="color-secondary">${{ totalPrice() }}/{{ package.plan }}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
import {COOKIES} from "../../constants/enums";

const DashboardLayout = () => import("../../layouts/DashboardLayout.vue");
const ErrorMessage = () => import("../../components/common/ErrorMessage.vue");
import client from '../../app/api/Client';
import {mapActions, mapState} from "vuex";
import {getCity, getCountry, getState} from "../../app/api/CommonRequest";
import Multiselect from 'vue-multiselect';
import {USER_LOGGED_IN} from "../../constants/action-type";

let stripe = Stripe(STRIPE_KEY);

export default {
    components: {
        ErrorMessage,
        DashboardLayout,
        Multiselect
    },
    data() {
        return {
            form: {
                id: null,
                package_id: null,
                stripe_source: '',
                street: '',
                country: '',
                state: '',
                city: '',
                postal_code: '',
                promoter: '',
            },
            package: {},
            billing: {},
            agree: false,
            mode: 'DEV', // DEV, PROD
            isLoading: false,
            publishableKey: '',
            items: [],
            couponCode: '',
            sponsorImage: '/img/secure-stripe-payment-logo.svg',
            button: {
                disabled: false,
                text: 'Checkout Now'
            },
            countryOptions: [],
            stateOptions: [],
            cityOptions: [],
            errors: {
                coupon: ''
            },
            sessionId: '',
            successLoading: false,
            discount: null,
            hasCouponOption: true,
        }
    },
    computed: {
        ...mapState(['user']),
        isEmpty() {
            return this.archivedJobs.length === 0;
        },
        slug() {
            return this.$route.params.slug;
        },
        promoter() {
            return _helper.getCookie(COOKIES.AFFILIATE) || null;
        }
    },
    methods: {
        ...mapActions([USER_LOGGED_IN]),
        async getCurrentPackage() {
            try {
                let {data: {data}} = await client().get(`/subscription/packages/${this.slug}`);
                this.package = data;
                this.form.package_id = this.package.id;

                if (!this.package.stripe) {
                    this.button.disabled = true;
                }

            } catch (e) {
            }
        },
        async checkout() {
            if (!this.agree) {
                this.$toast.error(this.$t('please agree to the terms and policy.'));
                return false;
            }

            this.isLoading = true;
            try {
                this.form.coupon = this.discount ? this.discount.code : '';
                this.form.promoter = this.promoter || '';
                let {data} = await client().post(`/checkout`, this.form);

                if (data.data.isSubscribed) {
                    this[USER_LOGGED_IN](data.data.user);
                    localStorage.setItem('sessionId', '');
                    this.sessionId = '';
                    this.$toast.success(this.$t('Subscription successful.'));
                    await this.$router.push({name: 'my-account'});
                    return;
                } else {
                    this.sessionId = data.data.session_id;
                }

                if (this.sessionId) {
                    localStorage.sessionId = this.sessionId;
                    let self = this;
                    stripe.redirectToCheckout({
                        sessionId: self.sessionId
                    }).then(function (result) {
                        if (result.error) {
                            self.$toast.error(result.error.message);
                        }
                    });
                } else {
                    this.$toast.error(this.$t('Invalid session id'));
                }
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = err.response.data.message;
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.showErrorMessage(err);
                }
            }
            this.isLoading = false;
        },
        showErrorMessage(e) {
            if (typeof e === 'object') {
                let {response: {status, data: {message}}} = e;
                if (status === 400) {
                    if (_.isArray(message)) {
                        message.forEach(msg => {
                            if (!_.isNull(msg)) {
                                this.$toast.error(this.$t(msg));

                            }
                        })
                    } else {
                        this.$toast.error(this.$t(message));
                    }
                }
            } else {
            }
        },
        async couponDiscount() {
            this.errors.coupon = '';
            // if (this.package.discount > 0) {
            //     this.$toast.error(this.$t('Coupon not applicable.'));
            //     return false;
            // }
            if (!this.couponCode) {
                this.errors.coupon = this.$t('Coupon is empty.');
                return false;
            }

            this.isLoading = true;
            try {
                let {data} = await client().get(`/coupon/${this.slug}/${this.couponCode}`);
                this.couponCode = '';
                if (data.data.discount > 0) {
                    this.discount = data.data;
                    this.package.coupon_discount = this.discount.discount;
                    this.package.discount_text = this.discount.discount_text;
                    this.$toast.success(this.$t('Coupon discount added.'));
                }
            } catch (error) {
                this.errors.coupon = error.response.data.message;
            }
            this.isLoading = false
        },
        totalPrice() {
            if (this.package.coupon_discount) {
                return this.discount.discounted_price;
            } else {
                return this.package.discounted_price;
            }
        },
        async checkSuccessfulPurchase() {
            if (this.$route.query.success && localStorage.sessionId) {
                this.successLoading = true;
                try {
                    let {data} = await client().post('subscription/verify', {
                        plan: this.$route.query.success,
                        sessionId: localStorage.sessionId
                    });
                    // this[USER_LOGGED_IN](data.data);
                    localStorage.sessionId = '';
                    _helper.removeCookie(COOKIES.AFFILIATE);
                    this.$toast.success(this.$t('Subscription successful.'));
                    await this.$router.push({name: 'my-account'});
                } catch (e) {
                    this.$toast.error(this.$t(e.response.data.message));
                }
                this.successLoading = false;
            }
        },

        async getBillingDetails() {
            try {
                await client().get(`/my-account/billing`).then(({data}) => {
                    if (data.status === 'SUCCESS') {
                        this.billing = data.data.billing;
                        this.setDataToForm(this.billing);
                    } else {
                        this.$toast.error(this.$t(data.message));
                    }
                });
            } catch (e) {
            }
        },

        findCountry() {
            getCountry().then((data) => {
                this.countryOptions = data;
            });
        },

        findState() {

            if (!this.form.country) {
                this.$toast.error(this.$t('Please select country first'));
                return 0;
            }

            getState(this.form.country.id).then((data) => {
                this.stateOptions = data;
            });
        },

        findCity() {
            if (!this.form.country || !this.form.state) {
                this.$toast.error(this.$t('Please select country and state first'));
                return 0;
            }

            getCity(this.form.country.id, this.form.state.id).then((data) => {
                this.cityOptions = data;
            });
        },

        setDataToForm(data) {
            this.form.id = data.id;
            this.form.street = data.address_line_1;
            this.form.country = {
                id: data.country.id,
                name: data.country.name
            };
            this.form.state = {
                id: data.state.id,
                name: data.state.name
            };
            this.form.city = {
                id: data.city.id,
                name: data.city.name
            };
            this.form.postal_code = data.postal_code;
        },

        async checkForCoupon() {
            let coupon = this.$route.query.coupon;
            if (!_.isEmpty(coupon)) {
                this.couponCode = coupon;
                this.hasCouponOption = false;
                await this.couponDiscount();
            }
        },
    },
    async mounted() {
        await this.checkSuccessfulPurchase();
        await this.getCurrentPackage();
        await this.getBillingDetails();
        await this.checkForCoupon();
    }
}
</script>

<style>
#card-element {
    background-color: #f5f7fd;
    border: 1px solid rgba(89, 125, 252, 0.3);
    height: 50px;
    padding: 15px 26px;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 5px;
    color: #1c2238;
}
</style>
